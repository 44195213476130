import { Link } from '@remix-run/react';
import AuthFrameSVG from '~/assets/images/auth/creatordao_auth_frame.svg';
import { URLS } from '~/common/constants/urls';

interface AuthPageLayoutProps {
    children: React.ReactNode;
}

export const AuthPageLayout = ({ children }: AuthPageLayoutProps) => {
    return (
        <div className="flex min-h-full flex-1 xs:flex-col xs:gap-5 xs:pb-5 md:flex-row md:gap-0 md:pb-0">
            <div className="relative flex-1 xs:min-h-[170px] xs:w-full md:w-1/2 md:min-w-[50%]">
                <Link to={URLS.PUBLIC.ROOT}>
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={AuthFrameSVG}
                        alt="CreatorDAO"
                    />
                </Link>
            </div>
            <div className="relative flex flex-1 flex-col xs:w-full xs:gap-8 xs:px-5 md:w-1/2 md:gap-10 md:px-[138px] md:pt-[100px] md:pb-10">
                {children}
            </div>
        </div>
    );
};
